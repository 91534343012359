<template>
  <spinner v-if="isLoading" />
  <template v-if="isInterview && !isDetail && !isCustomer">
    <button
      :disabled="isLoading"
      class="btn btn-orange col-auto mx-1 ms-2"
      @click.prevent="submit()"
    >
      {{ isLoading ? "Aguarde..." : "Salvar" }}
    </button>
    <button
      :disabled="isLoading"
      class="btn btn-outline-light col-auto mx-2 exit"
      @click.prevent="submit(true)"
    >
      Salvar e sair da entrevista
    </button>
  </template>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as types from "../store/types";
import { processFormErrors } from "@/helpers/interview_payloads";
import { processErrorMessages } from "@/helpers/errors";
import Spinner from "@/modules/shared/components/Spinner";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isInterview: false,
      isDetail: false,
      isCustomer: false,
    };
  },
  computed: {
    ...mapGetters({
      saving: types.SAVING_INTERVIEW,
      form: types.INTERVIEW_FORM,
      cashFlowLoading: types.INTERVIEW_CASH_FLOW_LOADING,
      proposalLoading: types.INTERVIEW_PROPOSAL_LOADING,
    }),
    isLoading: function () {
      return this.saving || this.cashFlowLoading || this.proposalLoading;
    },
  },
  mounted() {
    this.setIsInterview();
    this.setIsDetail();
    this.setIsCustomer();
  },
  methods: {
    ...mapActions({
      saveInterview: types.SAVE_INTERVIEW,
    }),
    ...mapMutations({
      savingInterviewState: types.MUTATE_SAVING_INTERVIEW,
    }),
    async submit(andExit = false) {
      const errors = await processFormErrors(this.form);
      const formattedErrors = errors.map(
        (error) => `<p class="message-text">${error}</p>`
      );
      if (errors.length > 0) {
        const messages = [
          "Os dados da entrevista não foram atualizados.",
          "Você deve solucionar as inconsistências da entrevista apontadas nas seguintes seções:",
          ...formattedErrors,
        ];
        this.$vfm.show("message", {
          type: "error",
          messages,
        });
      } else {
        this.savingInterviewState(true);
        this.saveInterview()
          .then((response) => {
            this.$vfm.show("message", {
              type: "success",
              messages: ["Entrevista atualizada com sucesso!"],
              backUrl: andExit && process.env.VUE_APP_URL,
            });
          })
          .catch((error) => {
            const errorsFromPayload = processErrorMessages(
              error?.response?.data
            );
            const messages = [
              "Não foi possível salvar os dados da entrevista.",
              ...errorsFromPayload,
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ];
            this.$vfm.show("message", {
              type: "error",
              messages,
            });
          })
          .finally(() => {
            this.savingInterviewState(false);
          });
      }
    },
    setIsInterview() {
      if (this.$route.name === "private-pre-interview") {
        this.isInterview = false;
      } else {
        this.isInterview = true;
      }
    },
    setIsDetail() {
      if (this.$route.name === "private-detail-interview") {
        this.isDetail = true;
      } else {
        this.isDetail = false;
      }
    },
    setIsCustomer() {
      if (this.$route.name === "private-customer-interview") {
        this.isCustomer = true;
      } else {
        this.isCustomer = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .exit {
    display: none !important;
  }
}

.btn {
  height: fit-content;
}
</style>
