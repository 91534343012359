<template>
  <confirm-modal />
  <form v-if="isInterview" @submit.prevent>
    <div class="card my-5">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            v-if="!paymentForInterview"
            src="~@/assets/img/chevron-down-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="paymentForInterview = !paymentForInterview"
          />
          <img
            v-if="paymentForInterview"
            src="~@/assets/img/chevron-up-solid.svg"
            alt="Futuro | Consultoria Financeira"
            @click="paymentForInterview = !paymentForInterview"
          />
          <h2>Forma de pagamento</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <template v-if="paymentForInterview">
        <div
          v-if="
            calculation &&
            calculation.parcels_values &&
            calculation.subscription_amount &&
            paymentForInterview &&
            !loading
          "
          class="row justify-content-center my-5"
        >
          <div
            class="col-auto m-3 blue-card"
            :class="{ selected: parcelsQuantity === 1, clickable: !isDetail }"
            @click="changeParcelsQuantity(1)"
          >
            <div class="h-100 inner-section">
              <div class="ribbon">
                <span class="ribbon__content"> MAIS ESCOLHIDO </span>
              </div>
              <h1 class="mb-3">Investimento à vista</h1>
              <p class="mb-3">
                <b>{{
                  formatCurrency(
                    calculation.parcels_values["0"].total_amount,
                    "BRL"
                  )
                }}</b>
                <br />
                <b
                  >+{{
                    formatCurrency(calculation.subscription_amount, "BRL")
                  }}</b
                >
                mensais
              </p>
              <p class="final-value">
                {{
                  formatCurrency(
                    calculation.parcels_values["0"].discount,
                    "BRL"
                  )
                }}
                <br />
                desconto total
              </p>
            </div>
          </div>
          <div
            class="col-auto m-3 blue-card"
            :class="{ selected: parcelsQuantity > 1, clickable: !isDetail }"
            @click="changeParcelsQuantity(selectedParcel)"
          >
            <div class="h-100 inner-section">
              <h1>Investimento parcelado</h1>
              <div class="w-100 px-3">
                <select
                  v-model="selectedParcel"
                  :disabled="isDetail"
                  class="form-select"
                  :class="{ 'select-disabled': isDetail }"
                  @change="onChangeParcels($event)"
                >
                  <option
                    v-for="parcel in parcels"
                    :key="parcel"
                    :value="parcel"
                  >
                    {{ parcel }} vezes
                  </option>
                </select>
              </div>
              <p>
                {{
                  formatCurrency(
                    calculation.parcels_values[(selectedParcel - 1).toString()]
                      .installment_amount,
                    "BRL"
                  )
                }}
                por {{ selectedParcel }} meses +
                {{ formatCurrency(calculation.subscription_amount, "BRL") }}
                mensais
              </p>
              <p
                v-if="
                  calculation.parcels_values[(selectedParcel - 1).toString()]
                    .discount > 0
                "
                class="final-value"
              >
                {{
                  formatCurrency(
                    calculation.parcels_values[(selectedParcel - 1).toString()]
                      .discount,
                    "BRL"
                  )
                }}
                <br />
                desconto total
              </p>
            </div>
          </div>
        </div>
        <div v-if="!loading" class="row">
          <div class="col-12">
            <button
              :disabled="loading"
              class="btn btn-outline-light col-auto mx-2 exit"
              @click.prevent="discountsOpened = !discountsOpened"
            >
              <img
                v-if="!discountsOpened"
                src="~@/assets/img/chevron-down-solid.svg"
                alt=""
                height="15"
                class="me-2"
              />
              <img
                v-if="discountsOpened"
                src="~@/assets/img/chevron-up-solid.svg"
                alt=""
                height="15"
                class="me-2"
              />
              {{ discountsOpened ? "Ocultar" : "Ver" }} opções adicionais
            </button>
          </div>
          <div v-if="discountsOpened" class="col-12">
            <div class="row mt-3">
              <div class="col-md-4 col-12">
                <label for="investmentPercentage" class="form-label">
                  Acréscimo com base no patrimônio líquido
                </label>
                <input
                  id="investmentPercentage"
                  v-model.lazy="investmentPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.investmentPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.investmentPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="discountPercentage" class="form-label">
                  Desconto sob valor do projeto financeiro
                </label>
                <input
                  id="discountPercentage"
                  v-model.lazy="discountPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.discountPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.discountPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                  <span v-if="v$.discountPercentage.outOfBounds.$invalid">
                    O valor de desconto deve ser até 30%
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="additionPercentage" class="form-label">
                  Acréscimo sob valor do projeto financeiro
                </label>
                <input
                  id="additionPercentage"
                  v-model.lazy="additionPercentage"
                  v-money="percentageFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{ 'is-invalid': v$.additionPercentage.$error }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.additionPercentage.minLength.$invalid">
                    O valor é inválido
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  requiredIf,
  minLength,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProposalPayload } from "@/helpers/interview_payloads";
import { getPercentageFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { floatToPercentage, percentageToFloat } from "@/helpers/converters";
import Spinner from "@/modules/shared/components/Spinner";
import Presentation from "@/modules/shared/components/Presentation";
import SubscriptionPlans from "./SubscriptionPlans";
import ConfirmModal from "@/modules/shared/components/ConfirmModal";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import * as subscriptionPlanTypes from "@/constants/subscriptionPlanTypes";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
export default {
  components: {
    Spinner,
    Presentation,
    SubscriptionPlans,
    ConfirmModal,
    Swiper,
    SwiperSlide,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const parcels = _.range(2, 13);
    const onSwiper = (swiper) => {};
    const onSlideChange = () => {};
    return {
      parcels,
      v$: useVuelidate({ $registerAs: props.interviewSection }),
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      selectedParcel: 12,
      parcelsQuantity: 1,
      recommendations: [],
      interest: 0,
      totalAmount: 0,
      installmentAmount: 0,
      consultantId: undefined,
      userConsultantId: undefined,
      discount: 0,
      recommendationDiscount: 0,
      servicesToContract: "",
      paymentMethod: "",
      supportFromAnotherConsultant: "no",
      closerConsultantId: undefined,
      token: "",
      subscriptionPlanId: undefined,
      loading: false,
      subscriptionPlansVisible: false,
      paymentForInterview: false,
      ownerLevel: undefined,
      loadingProposal: false,
      loadingRecs: false,
      errorPhone: false,
      cellPhoneWithError: [],
      onlySubscriptionKey: "only_subscription",
      discountsOpened: false,
      investmentPercentage: "",
      discountPercentage: "",
      additionPercentage: "",
    };
  },
  validations() {
    return {
      selectedParcel: {},
      parcelsQuantity: {},
      recommendations: {
        $each: helpers.forEach({
          id: {},
          name: { required },
          cellPhone: {
            minLength(value) {
              if (!value) {
                return true;
              } else {
                var trimmed = value.replace("+", "").replaceAll(" ", "");
                return trimmed.length >= 13;
              }
            },
          },

          email: { email },
          occupation: {},
          goal: {},
          comment: {},
          type: {},
        }),
        recommendationCellErrors() {
          return !this.errorPhone;
        },
      },
      servicesToContract: { required },
      closerConsultantId: {
        required: requiredIf(function () {
          return this.supportFromAnotherConsultant === "yes";
        }),
      },
      investmentPercentage: {
        minLength: minLength(7),
      },
      discountPercentage: {
        minLength: minLength(7),
        outOfBounds: function (value) {
          const convertedValue = percentageToFloat(value);
          return convertedValue >= 0 && convertedValue <= 30;
        },
      },
      additionPercentage: {
        minLength: minLength(7),
      },
    };
  },
  watch: {
    subscriptionPlanId(newVal) {
      if (newVal == subscriptionPlanTypes.FLUXO) {
        this.servicesToContract = this.onlySubscriptionKey;
      }
    },
  },
  computed: {
    ...mapGetters({
      goals: types.GOALS,
      paymentMethodTypes: types.PAYMENT_METHOD_TYPES,
      calculation: types.PROPOSAL_CALCULATION,
      isAnalyst: accountTypes.IS_HUNTER,
      user: accountTypes.USER,
      subscriptionPlans: types.SUBSCRIPTION_PLANS,
      formatCurrency: types.FORMAT_CURRENCY,
    }),
    payments: function () {
      if (!this.paymentMethodTypes) return {};
      return Object.fromEntries(
        Object.entries(this.paymentMethodTypes).filter(
          ([key, value]) =>
            this.parcelsQuantity === 1 ||
            (this.parcelsQuantity > 1 && key === "credit")
        )
      );
    },
    differentConsultant: function () {
      return this.userConsultantId != this.consultantId && !this.isAnalyst;
    },
    discount0: function () {
      return this.calculation?.discount_value_0 || 0;
    },
    discount5: function () {
      return this.calculation?.discount_value_5 || 0;
    },
    discount10: function () {
      return this.calculation?.discount_value_7_5 || 0;
    },

    discount15: function () {
      return this.calculation?.discount_value_10 || 0;
    },
    breakpoints() {
      return {
        768: {
          slidesPerView: 1.5,
        },
        992: {
          slidesPerView: 2.5,
        },
        1200: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: this.subscriptionPlans?.length || 4,
        },
      };
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
  },
  async mounted() {
    if (this.isAnalyst && this.supportFromAnotherConsultant === "no") {
      this.supportFromAnotherConsultant = "yes";
    }
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getProposal: types.GET_PROPOSAL,
      getCustomerInfo: types.GET_CUSTOMER_INFO,
      autoSaveProposal: types.AUTO_SAVE_PROPOSAL,
      calculateProposal: types.CALCULATE_PROPOSAL,
    }),
    ...mapMutations({
      mutateProposal: types.MUTATE_PROPOSAL,
      changeLoadingState: types.MUTATE_INTERVIEW_PROPOSAL_LOADING,
    }),
    async fetchProposal() {
      this.loading = true;
      try {
        const response = await this.getProposal(this.$route.params.customerId);
        const {
          installment_quantity,
          services_to_contract,
          payment_method,
          closer_consultant_id,
          interest,
          total_amount,
          installment_amount,
          discount,
          recommendation_discount,
          recommendations,
          subscription_plan_id,
          investment_percentage,
          discount_percentage,
          addition_percentage,
        } = response.data;
        this.investmentPercentage = floatToPercentage(investment_percentage);
        this.discountPercentage = floatToPercentage(discount_percentage);
        this.additionPercentage = floatToPercentage(addition_percentage);
        this.selectedParcel =
          installment_quantity > 1 ? installment_quantity : 12;
        this.servicesToContract = services_to_contract;
        this.paymentMethod = payment_method;
        this.interest = interest;
        this.totalAmount = total_amount;
        this.installmentAmount = installment_amount;
        this.discount = discount;
        this.recommendationDiscount = recommendation_discount || 0;
        this.subscriptionPlanId =
          subscription_plan_id || subscriptionPlanTypes.ESSENCIAL;
        if (this.subscriptionPlanId === subscriptionPlanTypes.FLUXO) {
          this.servicesToContract = this.onlySubscriptionKey;
        }
        this.recommendations = recommendations.map((recommendation) => {
          const {
            id,
            name,
            cell_phone,
            email,
            occupation,
            goal,
            comment,
            type,
            customer_id,
          } = recommendation;
          return {
            id,
            name,
            cellPhone: cell_phone,
            email,
            occupation,
            goal,
            comment,
            type,
            customer_id,
          };
        });
        const customerInfoResponse = await this.getCustomerInfo(
          this.$route.params.customerId
        );
        this.consultantId = customerInfoResponse.data?.consultant_id;
        this.userConsultantId = this.user?.user?.appify_consultant_id;
        this.ownerLevel =
          customerInfoResponse.data?.customer_consultant?.id_consultant_role;
        if (
          this.isAnalyst ||
          closer_consultant_id ||
          this.differentConsultant
        ) {
          this.supportFromAnotherConsultant = "yes";
        } else {
          this.supportFromAnotherConsultant = "no";
        }
        if (closer_consultant_id) {
          this.closerConsultantId = closer_consultant_id;
        } else if (this.differentConsultant) {
          this.closerConsultantId = this.userConsultantId;
        }
        this.changeParcelsQuantity(installment_quantity);
        const payload = getProposalPayload(this);
        this.mutateProposal(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter a proposta do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
        this.changeLoadingState(false);
      }
    },
    async fetchAll() {
      this.changeLoadingState(true);
      try {
        await Promise.all([
          this.fetchProposal(),
        ]);
      } catch (error) {
      } finally {
        this.changeLoadingState(false);
      }
    },
    preSave() {
      const payload = getProposalPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateProposal(payload);
        this.autoSave(payload);
      }
    },
    async autoSave(payload) {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.autoSaveProposal(payload)
        .then((response) => {
          this.calculate();
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente a proposta do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangeParcels(event) {
      this.changeParcelsQuantity(event.target.value);
    },
    changeParcelsQuantity(value) {
      if (!this.isDetail) {
        if (this.parcelsQuantity !== value) {
          this.paymentMethod = "credit";
        }
        this.parcelsQuantity = value;
        if (this.calculation?.parcels_values) {
          const parcelValues =
            this.calculation.parcels_values[
              (this.parcelsQuantity - 1).toString()
            ];
          this.interest = parcelValues.interest;
          this.totalAmount = parcelValues.total_amount;
          this.installmentAmount = parcelValues.installment_amount;
          this.discount = parcelValues.discount;
          this.preSave();
        }
      }
    },
    calculate() {
      this.loading = true;
      this.calculateProposal(this.token)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter os dados de proposta do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 800px) {
  .ribbon {
    padding-right: 295px !important;
  }
}
.blue-card {
  padding: 30px 20px;
  width: 500px;
  height: 300px;
  h1 {
    font-size: 25px !important;
    font-weight: 600;
    text-align: center;
  }
}
.final-value {
  font-size: 26px !important;
  line-height: 1.2 !important;
  font-weight: 600;
  color: #ffeb98;
}
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  padding-right: 515px;
  padding-bottom: 315px;
}
.ribbon__content {
  left: -50px;
  top: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: var(--orange);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 2px dotted #fff;
  outline: 5px solid var(--orange);
}
.ribbon::before {
  top: 0;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  box-sizing: content-box;
}
.ribbon::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #f19066;
}

.error-bg {
  background-color: rgba(220, 53, 69, 0.8);
}

.margin-button {
  margin-top: 35px;
}

@media (max-width: 767.98px) {
  .column-mobile {
    flex-direction: column-reverse;
  }

  .margin-button {
    margin-top: 0px;
  }
}

option:disabled {
  background-color: #f0f0f0 !important; /* Change the background color */
  color: #ccc !important; /* Change the text color */
}
</style>
