<template>
  <div class="wrapper mt-3" id="app">
    <div class="card-form">
      <div class="card-list">
        <div class="card-item" v-bind:class="{ '-active': isCardFlipped }">
          <div class="card-item__side -front">
            <div
              class="card-item__focus"
              :class="{ '-active': focusElementStyle }"
              :style="focusElementStyle"
              ref="focusElement"
            ></div>
            <div class="card-item__cover">
              <img
                :src="
                  'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/credit_card/' +
                  currentCardBackground +
                  '.jpeg'
                "
                class="card-item__bg"
              />
            </div>
            <div class="card-item__wrapper">
              <div class="card-item__top">
                <img
                  src="https://futurogfp-assets.s3.sa-east-1.amazonaws.com/credit_card/chip.png"
                  class="card-item__chip"
                />
                <div class="card-item__type">
                  <transition name="slide-fade-up">
                    <img
                      :src="
                        'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/credit_card/' +
                        getCardType +
                        '.png'
                      "
                      v-if="getCardType"
                      :key="getCardType"
                      alt=""
                      class="card-item__typeImg"
                    />
                  </transition>
                </div>
              </div>
              <label
                for="cardNumber"
                class="card-item__number"
                ref="cardNumber"
              >
                <template v-if="getCardType === 'amex'">
                  <span v-for="(n, index) in amexCardMask" :key="index">
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__numberItem"
                        v-if="
                          index > 4 &&
                          index < 14 &&
                          cardNumber.length > index &&
                          n.trim() !== ''
                        "
                      >
                        *
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        :key="index"
                        v-else-if="cardNumber.length > index"
                      >
                        {{ cardNumber[index] }}
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        v-else
                        :key="index + 1"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>
                <template v-else>
                  <span v-for="(n, index) in otherCardMask" :key="index">
                    <transition name="slide-fade-up">
                      <div
                        class="card-item__numberItem"
                        v-if="
                          index > 4 &&
                          index < 15 &&
                          cardNumber.length > index &&
                          n.trim() !== ''
                        "
                      >
                        *
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        :key="index"
                        v-else-if="cardNumber.length > index"
                      >
                        {{ cardNumber[index] }}
                      </div>
                      <div
                        class="card-item__numberItem"
                        :class="{ '-active': n.trim() === '' }"
                        v-else
                        :key="index + 1"
                      >
                        {{ n }}
                      </div>
                    </transition>
                  </span>
                </template>
              </label>
              <div class="card-item__content">
                <label for="cardName" class="card-item__info" ref="cardName">
                  <div class="card-item__holder">Nome</div>
                  <transition name="slide-fade-up">
                    <div class="card-item__name" v-if="cardName.length" key="1">
                      <transition-group name="slide-fade-right">
                        <span
                          class="card-item__nameItem"
                          v-for="(n, index) in cardName.replace(/\s\s+/g, ' ')"
                          :key="index + 1"
                          >{{ n }}</span
                        >
                      </transition-group>
                    </div>
                    <div class="card-item__name" v-else key="2">Seu Nome</div>
                  </transition>
                </label>
                <div class="card-item__date" ref="cardDate">
                  <label for="cardMonth" class="card-item__dateTitle"
                    >Expira em</label
                  >
                  <label for="cardMonth" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardMonth" v-bind:key="cardMonth">{{
                        cardMonth
                      }}</span>
                      <span v-else key="2">MM</span>
                    </transition>
                  </label>
                  &nbsp;/&nbsp;
                  <label for="cardYear" class="card-item__dateItem">
                    <transition name="slide-fade-up">
                      <span v-if="cardYear" v-bind:key="cardYear">{{
                        String(cardYear).slice(2, 4)
                      }}</span>
                      <span v-else key="2">YY</span>
                    </transition>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-item__side -back">
            <div class="card-item__cover">
              <img
                :src="
                  'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/credit_card/' +
                  currentCardBackground +
                  '.jpeg'
                "
                class="card-item__bg"
              />
            </div>
            <div class="card-item__band"></div>
            <div class="card-item__cvv">
              <div class="card-item__cvvTitle">CVV</div>
              <div class="card-item__cvvBand">
                <span v-for="(n, index) in cardCvv" :key="index"> * </span>
              </div>
              <div class="card-item__type">
                <img
                  :src="
                    'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/credit_card/' +
                    getCardType +
                    '.png'
                  "
                  v-if="getCardType"
                  class="card-item__typeImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-form__inner">
        <form>
          <div class="row">
            <div class="col-12">
              <label for="cardNumber" class="form-label">
                Número do cartão
              </label>
              <input
                type="text"
                id="cardNumber"
                ref="cardNumberInput"
                class="form-control"
                v-mask="generateCardNumberMask"
                v-model="cardNumber"
                @focus="focusInput"
                @blur="blurInput"
                data-ref="cardNumber"
                autocomplete="off"
                :class="{ 'is-invalid': v$.cardNumber.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.cardNumber.required.$invalid">
                  Você deve informar o número do cartão
                </span>
                <span v-if="v$.cardNumber.valid.$invalid">
                  O número do cartão é inválido
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label for="cardName" class="form-label"> Nome no cartão </label>
              <input
                type="text"
                id="cardName"
                class="form-control"
                v-model="cardName"
                @focus="focusInput"
                @blur="blurInput"
                @input="(val) => (cardName = cardName.toUpperCase())"
                data-ref="cardName"
                autocomplete="off"
                :class="{ 'is-invalid': v$.cardName.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.cardName.required.$invalid">
                  Você deve informar o nome no cartão
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-4 col-6">
              <label for="cardMonth" class="form-label"> Expiração </label>

              <select
                id="cardMonth"
                v-model="cardMonth"
                @focus="focusInput"
                @blur="blurInput"
                data-ref="cardDate"
                class="form-select"
                :class="{ 'is-invalid': v$.cardMonth.$error }"
              >
                <option value="" disabled selected>Mês</option>
                <option
                  v-for="n in 12"
                  :value="n < 10 ? '0' + n : n"
                  :disabled="n < minCardMonth"
                  :key="n"
                >
                  {{ n < 10 ? "0" + n : n }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="v$.cardMonth.required.$invalid">
                  Informe o mês
                </span>
              </div>
            </div>
            <div class="col-md-4 col-6">
              <label for="cardYear" class="form-label">&nbsp;</label>
              <select
                id="cardYear"
                v-model="cardYear"
                @focus="focusInput"
                @blur="blurInput"
                data-ref="cardDate"
                class="form-select"
                :class="{ 'is-invalid': v$.cardYear.$error }"
              >
                <option value="" disabled selected>Ano</option>
                <option
                  v-for="(n, index) in 12"
                  :value="index + minCardYear"
                  :key="n"
                >
                  {{ index + minCardYear }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="v$.cardYear.required.$invalid">
                  Informe o ano
                </span>
              </div>
            </div>
            <div class="col-md-4 col-12 mt-3 mt-sm-0">
              <label for="cardCvv" class="form-label"> CVV </label>
              <input
                type="text"
                id="cardCvv"
                class="form-control"
                v-model="cardCvv"
                v-mask="'####'"
                maxlength="4"
                @focus="flipCard(true)"
                @blur="flipCard(false)"
                data-ref="cardCvv"
                autocomplete="off"
                :class="{ 'is-invalid': v$.cardCvv.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.cardCvv.required.$invalid">
                  Informe o código
                </span>
                <span v-if="v$.cardCvv.minLength.$invalid">
                  Código inválido
                </span>
              </div>
            </div>
          </div>
          <div v-if="isCustomerDomestic" class="row mt-3">
            <div class="col-12">
              <label for="clientOwnsTheCardSubscription" class="form-label">
                O(a) titular do cartão é {{ customerName }}?
              </label>
              <select
                id="clientOwnsTheCardSubscription"
                v-model="clientOwnsTheCard"
                class="form-select"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </select>
            </div>
          </div>
          <template v-if="clientOwnsTheCard === 'no'">
            <div class="row mt-5">
              <h5>Dados do titular do cartão</h5>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label for="clientName" class="form-label"> Nome </label>
                <input
                  type="text"
                  id="clientName"
                  class="form-control"
                  v-model="clientName"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientName.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientName.required.$invalid">
                    Você deve informar o nome completo do titular do cartão
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="clientEmail" class="form-label"> E-mail </label>
                <input
                  type="text"
                  id="clientEmail"
                  class="form-control"
                  v-model="clientEmail"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientEmail.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientEmail.required.$invalid">
                    Você deve informar o e=mail do titular do cartão
                  </span>
                  <span v-if="v$.clientEmail.email.$invalid">
                    Você deve informar o e=mail um e-mail válido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="clientDocument" class="form-label">
                  CPF/CNPJ
                </label>
                <input
                  type="text"
                  id="clientDocument"
                  class="form-control"
                  v-model="clientDocument"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientDocument.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientDocument.required.$invalid">
                    Você deve informar o CPF/CNPJ do titular do cartão
                  </span>
                  <span v-if="v$.clientDocument.valid.$invalid">
                    O CPF/CNPJ informado é inválido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="clientPhoneNumber" class="form-label">
                  Telefone
                </label>
                <vue-tel-input
                  type="text"
                  id="clientPhoneNumber"
                  class="form-control"
                  v-model="clientPhoneNumber"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientPhoneNumber.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientPhoneNumber.required.$invalid">
                    Você deve informar o telefone do titular do cartão
                  </span>
                  <span v-if="v$.clientPhoneNumber.minLength.$invalid">
                    O telefone informado é inválido
                  </span>
                  <span v-if="v$.clientPhoneNumber.maxLength.$invalid">
                    O telefone informado é inválido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <h5>Endereço do titular</h5>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="countrySelect" class="form-label"> País </label>
                <select
                  id="countrySelect"
                  v-model="clientCountry"
                  class="form-select"
                  :class="{ 'is-invalid': v$.clientCountry.$error }"
                >
                  <option
                    v-for="countrySelected in countriesList"
                    :key="countrySelected.sigla2"
                    :value="countrySelected.sigla2"
                  >
                    {{ countrySelected.nome }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="v$.clientCountry.required.$invalid">
                    Você deve informar um país
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="clientCep" class="form-label"> CEP </label>
                <input
                  v-if="clientCountry === 'BR'"
                  type="text"
                  id="clientCep"
                  v-mask="'##.###-###'"
                  class="form-control"
                  v-model="clientCep"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientCep.$error }"
                  @change="searchPartnerCep"
                />
                <input
                  v-else
                  type="text"
                  id="clientCep"
                  class="form-control"
                  v-model="clientCep"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientCep.$error }"
                  @change="searchPartnerCep"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientCep.required.$invalid">
                    Você deve informar o CEP do titular do cartão
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <label for="clientAddress" class="form-label"> Endereço </label>
                <input
                  type="text"
                  id="clientAddress"
                  class="form-control"
                  v-model="clientAddress"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientAddress.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientAddress.required.$invalid">
                    Você deve informar o endereço do titular do cartão
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-8 col-12">
                <label for="clientAddressDistrict" class="form-label">
                  Bairro
                </label>
                <input
                  type="text"
                  id="clientAddressDistrict"
                  class="form-control"
                  v-model="clientAddressDistrict"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientAddressDistrict.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientAddressDistrict.required.$invalid">
                    Você deve informar o bairro do endereço do titular
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="clientAddressNumber" class="form-label">
                  Número
                </label>
                <input
                  type="text"
                  id="clientAddressNumber"
                  class="form-control"
                  v-model="clientAddressNumber"
                  autocomplete="off"
                  :class="{ 'is-invalid': v$.clientAddressNumber.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientAddressNumber.required.$invalid">
                    Você deve informar o número do endereço do titular do cartão
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4 col-12">
                <label for="partnerAddressState" class="form-label">
                  Estado
                </label>
                <select
                  id="clientState"
                  v-model="clientState"
                  class="form-select"
                  :class="{ 'is-invalid': v$.clientState.$error }"
                >
                  <option
                    v-for="key in Object.keys(addressStates)"
                    :key="key"
                    :value="key"
                  >
                    {{ addressStates[key] }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="v$.clientState.required.$invalid">
                    Você deve informar o estado
                  </span>
                </div>
              </div>
              <div class="col-md-8 col-12">
                <label for="clientCity" class="form-label"> Cidade </label>
                <input
                  id="clientCity"
                  v-model="clientCity"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.clientCity.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.clientCity.required.$invalid">
                    Você deve informar a cidade
                  </span>
                </div>
              </div>
            </div>
          </template>
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-orange col-12"
                :disabled="loading"
                type="button"
                @click="sendPaymentData"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, requiredIf, email } from "@vuelidate/validators";
import { mapGetters, mapActions } from "vuex";
import JSEncrypt from "jsencrypt";
import * as sharedTypes from "@/modules/shared/store/types";
import cpfIsValid from "../../../validators/cpf";
import cnpjIsValid from "../../../validators/cnpj";
import { onlyDigits } from "@/helpers/converters";
export default {
  props: {
    customerName: String,
    buttonTitle: String,
    publicKey: String,
    loading: Boolean,
    isCustomerDomestic: Boolean,
  },
  setup(props) {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      clientOwnsTheCard: "yes",
      clientName: "",
      clientEmail: "",
      clientDocument: "",
      clientPhoneNumber: "",
      clientCountry: "BR",
      clientCep: "",
      clientAddress: "",
      clientAddressDistrict: "",
      clientCity: "",
      clientState: "",
      clientAddressNumber: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      visaRegex: new RegExp("^4"),
      mastercardRegex: new RegExp("^5[1-5]"),
      americanExpressRegex: new RegExp("^(34|37)"),
      discoverRegex: new RegExp("^6011"),
    };
  },
  validations() {
    return {
      cardName: { required },
      cardNumber: {
        required,
        valid(value) {
          if (value.match(this.visaRegex) != null) return true; // visa
          if (value.match(this.mastercardRegex) != null) return true; // master
          if (value.match(this.americanExpressRegex) != null) return true; // american express
          if (value.match(this.discoverRegex) != null) return true; // discover
          return value && value.length === 19;
        },
      },
      cardMonth: { required },
      cardYear: { required },
      cardCvv: {
        required,
        minLength: minLength(3),
      },
      clientOwnsTheCard: { required },
      clientName: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientEmail: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
        email,
      },
      clientDocument: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
        valid(value) {
          return (
            this.clientOwnsTheCard === "yes" ||
            cpfIsValid(value) ||
            cnpjIsValid(value)
          );
        },
      },
      clientPhoneNumber: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
        minLength: requiredIf(
          (value, vm) => this.clientOwnsTheCard === "no" && value?.length >= 9
        ),
        maxLength: requiredIf(
          (value, vm) => this.clientOwnsTheCard === "no" && value?.length <= 20
        ),
      },
      clientCountry: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientCep: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientAddress: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientAddressDistrict: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientCity: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientState: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientCountry: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
      clientAddressNumber: {
        required: requiredIf((value, vm) => this.clientOwnsTheCard === "no"),
      },
    };
  },
  mounted() {
    if (!this.isCustomerDomestic) {
      this.clientOwnsTheCard = "no";
    }
    this.cardNumberTemp = this.otherCardMask;
    this.$refs.cardNumberInput.focus();
    this.getAddressStates();
    this.getCountriesList();
  },
  computed: {
    ...mapGetters({
      countriesList: sharedTypes.COUNTRIES_LIST,
      addressStates: sharedTypes.ADDRESS_STATES,
    }),
    getCardType() {
      let number = this.cardNumber;
      if (number.match(this.visaRegex) != null) return "visa";
      if (number.match(this.mastercardRegex) != null) return "mastercard";
      if (number.match(this.americanExpressRegex) != null) return "amex";
      if (number.match(this.discoverRegex) != null) return "discover";
      return undefined; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
    buttonText() {
      if (this.loading) {
        return "Estamos concluindo a operação, aguarde...";
      }
      if (this.buttonTitle) {
        return this.buttonTitle;
      }
      return "Pagar";
    },
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
  },
  methods: {
    ...mapActions({
      getCountriesList: sharedTypes.GET_COUNTRIES_LIST,
      getAddressStates: sharedTypes.GET_ADDRESS_STATES,
      searchCepAddress: sharedTypes.GET_CEP,
    }),
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    searchPartnerCep(event) {
      const cep = event.target.value;
      if (this.clientCountry === "BR" && cep.length === 10) {
        this.searchCepAddress(onlyDigits(cep))
          .then((response) => {
            this.clientAddress = response.data.logradouro;
            this.clientAddressDistrict = response.data.bairro;
            this.clientCity = response.data.localidade;
            this.clientState = response.data.uf;
          })
          .catch((error) => {
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "Não foi possível encontrar o endereço com o CEP informado.",
                "Entre em contato com o time de TI antes de prosseguir com a operação.",
              ],
            });
          });
      }
    },
    async sendPaymentData() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(this.publicKey);
      let creditCardData = {
        card: {
          number: encrypt.encrypt(onlyDigits(this.cardNumber)),
          name: encrypt.encrypt(this.cardName),
          expiration: {
            month: encrypt.encrypt(`${this.cardMonth}`),
            year: encrypt.encrypt(`${this.cardYear}`),
          },
          cvv: encrypt.encrypt(this.cardCvv),
        },
        customer_owns_the_card: this.clientOwnsTheCard === "yes",
      };
      if (this.clientOwnsTheCard === "no") {
        creditCardData = {
          ...creditCardData,
          customer: {
            full_name: this.clientName,
            email: this.clientEmail,
            document: onlyDigits(this.clientDocument),
            phone: onlyDigits(this.clientPhoneNumber),
            address: this.clientAddress,
            district: this.clientAddressDistrict,
            city: this.clientCity,
            state: this.clientState,
            country: this.clientCountry,
            cep: onlyDigits(this.clientCep),
            address_number: this.clientAddressNumber,
          },
        };
      }
      this.$emit("pay", creditCardData);
    },
    getEncryptedData(data) {
      let ivHex = CryptoJS.enc.Hex.stringify(iv);
      return CryptoJS.AES.encrypt(data, secretKey, { iv: ivHex }).toString();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background: #ddeefc;
  font-size: 16px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  display: flex;
  padding: 0px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    padding: 180px 0 0 0;

    @media screen and (max-width: 576px) {
      padding: 165px 0 0 0;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 15px;
    }

    @media screen and (max-width: 1450px) {
      &.-active {
        width: 12px !important;
      }
    }

    @media screen and (max-width: 480px) {
      width: 13px;
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 90px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    font-size: 24px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    span {
      color: #1a3b5d;
    }

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
</style>
