<template>
  <div>
    <!-- First modal -->
    <vue-final-modal
      v-model="showModal"
      classes="modal__container"
      content-class="modal__content"
      name="message"
      :resize="true"
      :max-width="400"
    >
      <template #default="{ params }">
        <button class="close" @click="showModal = false">
          <img src="~@/assets/img/close.svg" height="30" />
        </button>
        <img
          v-if="params.type === 'error'"
          src="~@/assets/img/error.svg"
          height="100"
        />
        <img
          v-else-if="params.type === 'success'"
          src="~@/assets/img/success.svg"
          height="100"
        />
        <img v-else src="~@/assets/img/warning.svg" height="100" />
        <span class="title mt-3">
          {{ getModalTitle(params.type) }}
        </span>
        <div class="content mt-3 px-3">
          <div
            v-for="(message, index) in params.messages"
            :key="`message${index}`"
            v-html="message"
            class="text-center"
          ></div>
        </div>
        <div
          class="col-12 d-flex align-items-center justify-content-around mt-3"
        >
          <button
            v-if="params.cancelCallback || params.type === 'confirmation'"
            class="btn btn-red"
            @click="showModal = false"
          >
            Cancelar
          </button>
          <button class="btn btn-blue" @click="confirm(params.backUrl)">
            {{ params.cancelCallback ? "Confirmar" : "OK" }}
          </button>
        </div>
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
  }),
  methods: {
    getModalTitle(type) {
      if (type === "success") {
        return "Sucesso!";
      }
      if (type === "error") {
        return "Oops!";
      }
      return "Atenção!";
    },
    confirm(backUrl) {
      this.showModal = false;
      if (backUrl) {
        window.location.href = backUrl;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  border-radius: 20px;
  background-color: var(--grey);
  color: var(--black-txt);
}
.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--black-txt);
}
.content {
  flex-grow: 1;
  overflow-y: auto;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: 300;
  text-align: center;
  color: var(--black-txt);
}
.close {
  position: absolute;
  background: transparent;
  top: 0.7rem;
  right: 0.7rem;
}
.btn {
  width: 130px;
}

::v-deep(.message-text) {
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
}
</style>
